import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoIosArrowBack } from 'react-icons/io';
import { TiWarningOutline } from 'react-icons/ti';
import { withRouter } from 'react-router-dom';
import { Col, Form, FormGroup, Input, Label, Row, UncontrolledAlert } from 'reactstrap';
import qs from 'vl-common/src/lib/query-string';
import ConfigLink from '../../../core/configs/config.link';
import { LoadingButton } from 'vl-common/src/components/LoadingButton';
import AccountLayout from './Components/AccountLayout';
import PrivacyPolicyModal from './Components/PrivacyPolicyModal';
import TermsAndConditionsModal from './Components/TermsAndConditionsModal';

import useApi from 'vl-common/src/hooks/useApi';
import { useRuntimeConfig } from 'vl-common/src/hooks/Runtime';

function ForgetPassword({ history }) {
  const { Api } = useApi();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const [errorApi, setErrorApi] = useState('');
  const [loading, setLoading] = useState(false);
  const { APP_CLIENT_ID } = useRuntimeConfig();

  let to = null;
  useEffect(() => {
    to = setTimeout(() => {
      reset({});
    }, 1000);

    return function unMount() {
      if (!to) clearInterval(to);
    };
  }, []);

  let progress = true;
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  let isInProgress = async function () {
    let i = 10;
    do {
      i--;
      console.debug('progress ...');
      await sleep(200);
      if (i <= 0) {
        progress = false;
        break;
      }
    } while (progress);
  };

  const onSubmit = async (data) => {
    try {
      setErrorApi('');
      setLoading(true);

      //await

      let breakTheFlow = false;

      Api.user
        .forgotPassword({
          clientId: APP_CLIENT_ID,
          username: data.email
        })
        .then((res) => {
          if (res) {
            if (res.data)
              if (res.data.CodeDeliveryDetails)
                if (res.data.CodeDeliveryDetails.DeliveryMedium.toLowerCase() === 'temp') {
                  breakTheFlow = true;
                  history.push({
                    pathname: ConfigLink.wrongFlow
                  });
                  return;
                }

            progress = false;
          }
        })
        .catch((er) => {
          console.error('rv1->', er);
          progress = false;
        });
      await isInProgress();

      if (breakTheFlow) return;

      let searchString = qs.stringify({
        normalPsw: true // normal reset password flow, not expire one
      });
      history.push({
        pathname: ConfigLink.ResetYourPassword,
        search: searchString,
        state: { email: data.email }
      });
    } catch (err) {
      setErrorApi(err.message);
    }
    setLoading(false);
  };

  return (
    <AccountLayout>
      <Row>
        <Col className="text-left" sm="12">
          <span
            className="vl-back-button-holder vs-fs-medium font-weight-bolder d-block"
            onClick={() => {
              history.push(ConfigLink.AccountLogin);
            }}
          >
            <IoIosArrowBack className="icon" /> Back
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="text-left mx-auto px-md-4" sm="12" md={{ size: 5 }}>
          <p className="mt-5 account-page-label">
            Forgot <span>Password</span>
          </p>

          <p className="account-text">
            Enter the email address of your Virtual Lucy account and we’ll email you a code to reset your password.
          </p>

          <div className="mt-2">
            <UncontrolledAlert className="account-login-alert" color="danger" isOpen={errorApi !== ''}>
              <TiWarningOutline size="1.5em" /> {errorApi}
            </UncontrolledAlert>
          </div>

          <Form onSubmit={handleSubmit(onSubmit)} className="mt-5 text-left validation">
            <input type="hidden" value="somethingfg" />
            <FormGroup>
              <Label className="account-text" for="email">
                Your email address<span className="vl-orange">*</span>
              </Label>
              <Input
                type="text"
                name="email"
                id="email"
                autoComplete="none"
                placeholder="email@site.com"
                innerRef={register({
                  required: {
                    value: true,
                    message: 'This field is required'
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'invalid email address'
                  }
                })}
              />
              {errors.email && <p>{errors.email}</p>}
            </FormGroup>
            <LoadingButton
              className="mt-5 py-2 btn-block vl-bg-orange"
              type="submit"
              loading={loading}
              disabled={loading} //|| !isFormValid()
            >
              Submit
            </LoadingButton>
            <div className="mt-5 text-center">
              <br />
              <br />
            </div>
          </Form>
        </Col>
      </Row>
      <TermsAndConditionsModal
        close={() => {
          setShowTermsAndConditionsModal(false);
        }}
        show={showTermsAndConditionsModal}
      />
      <PrivacyPolicyModal
        close={() => {
          setShowPrivacyPolicyModal(false);
        }}
        show={showPrivacyPolicyModal}
      />
    </AccountLayout>
  );
}

export default withRouter(ForgetPassword);
